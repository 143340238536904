body.page-template-template-about {

	.brand {
		svg * {
			fill: whitesmoke;
		}

		&:hover {
			svg * {
				fill: $gray-light;
			}
		}
	}

	main.main {
		background: $white;
		min-height: 100vh;
	}


}

.about-gifs {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	padding-bottom: 40%;
	overflow: hidden;
}

.about-gif {
	position: absolute;
	width: 100%;
	height: auto;
	top: 0;
	left: 0;
	z-index: 0;
	mix-blend-mode: screen;
	transform: translateY(-30%) rotate(180deg);

	&:first-of-type {
		mix-blend-mode: normal;
	}
}


.about-logo {
	width: 65vw;
	height: 0;
	margin-left: 30vw;
	margin-top: 11vh;
	padding-bottom: 22%;
	z-index: 2;
	position: relative;

	//-mix-blend-mode: screen;
	//opacity: 0.15;

	svg {
		position: absolute;
//		height: 0;
//		padding-bottom: 36.2%;
		width: 100%;
		height: 100%;
		
		fill: $black;

		// image {
		// 	clip-path: url(#logo-mask);
		// }
	}

	@include media("<md") {
		margin-left: 0;
		width: 90vw;
		padding-bottom: 40%;
	}
}

.about-text {
	background: $white;
	//padding: 10px;
	box-shadow: 0px 0px 200px 100px $white;

	> p {
		font-size: $font-size-h6;
	}
}

.about-credits {
	hr {
		border-top: 1px solid $brand-primary;
		width: 100%;
	}

	p {
		font-size: $font-size-sm;
	}
}

.about-vomit {
	width: 100%;
	height: 0;
	padding-bottom: 80%;
}