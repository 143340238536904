.template-basic {

	div:hover {
		cursor: pointer;
	}

	.full-width {
		max-width: 100%;
		width: 100%;
		flex: 100%;
	}

}