body.transition-out {

	.about-gifs,
	.about-logo,
	.about-text,
	.about-press {
		opacity: 0 !important;
	}
}


.about-gifs,
.about-logo,
.about-text,
.about-press {
	opacity: 1;
}

	.about-gifs {
		transition: opacity 3500ms;
	}

	.about-text,
	.about-logo {
		transition: opacity 1250ms;
	}

	.about-press {
		transition: opacity 2000ms;	
	}
