/*********************************************
	* FONTS 
	* List all your custom fonts here
	* each in their own parenthesis
	*
	*
	* 1) $font_family
	* 2) $file_name
	* 3) Weight
	* 4) Style

*********************************************/

$fonts: 
	('Wedding' Wedding-Regular 			normal	normal),
	('Wedding' Wedding-RegularItalic 	normal	italic),
	('Wedding' Wedding-Bold 			bold 	normal),
	('Wedding' Wedding-BoldItalic		bold 	italic),

	('Circular' CircularStd-Book 		normal	normal),
	('Circular' CircularStd-BookItalic 	normal	italic),
	('Circular' CircularStd-Bold 		bold 	normal),
	('Circular' CircularStd-BoldItalic	bold 	italic),
;

/*********************************************
	*CREATE THE @FONT-FACE DECLARATIONS
*********************************************/
@each 
	$font_family, 
	$file_name, 
	$weight, 
	$style 
	
	in $fonts {

	@font-face {
		font-family: $font_family;
		src: url('../fonts/#{$file_name}.eot');
		src: url('../fonts/#{$file_name}.eot?#iefix') format('embedded-opentype');
		src: url('../fonts/#{$file_name}.woff') format('woff'), 
			 url('../fonts/#{$file_name}.woff') format('woff'), 
		     url('../fonts/#{$file_name}.ttf')  format('truetype'); 
		font-weight: $weight;
		font-style: $style;     
	}
	
}