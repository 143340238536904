.modal-backdrop.show {
	z-index: $zindex-modal-bg;
	background: $white;
}

.modal {
	z-index: $zindex-modal;
}

.modal.show {

	
	.modal-dialog {
		margin: 0;
		@include center-horizontal;

		background: $black;
		color: $white;

		@include media("<lg") {
			width: 100%;
			@include center-horizontal;
			top: 120px;

		}

		@media (min-height: 600px) {
			@include center-both;
		}
	}


	.modal-header {
		display: inline-block;
		text-align: right;
		position: absolute;
		width: 100%;
		top: 0;
		z-index: 2;
		padding: 0;

		.close {
			font-size: $font-size-h2;
			font-family: $font-family-sans-serif;
			font-weight: normal;
			outline: none;
			text-shadow: none;
			//width: 0px;
			//height: 0px;
			padding: 20px 22px;
			opacity: 1;

			@include media("<lg") {
				padding: 10px 12px;
				font-size: $font-size-h4;
			}
		}
	}


	.modal-body {
		padding: $spacer $spacer*2 !important;

		.btn-link {
			text-shadow: 1px 0 0 black, -1px 0 0 black;

			&:hover {
				border-bottom: 0 !important;
				background-image: none !important;
			}
		}
	}

}



.aa-modal {
	display: none;
}