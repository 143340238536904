/* Magnific Popup CSS */

////////////////////////
//
// Contents:
//
// 1. Default Settings
// 2. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 3. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////



////////////////////////
// 1. Default Settings
////////////////////////

$overlay-color:                   #ffffff !default;
$overlay-opacity:                 0.9 !default;
$shadow:                          0 0 rgba(0, 0, 0, 0) !default; // shadow on image or iframe
$popup-padding-left:              8px !default; // Padding from left and from right side
$popup-padding-left-mobile:       6px !default; // Same as above, but is applied when width of window is less than 800px

$z-index-base:                    $zindex-dropdown-backdrop !default; // Base z-index of popup
$include-arrows:                  true !default; // include styles for nav arrows
$controls-opacity:                1		!default;
$controls-color:                  #FFF !default;
$inner-close-icon-color:          #333 !default;
$controls-text-color:             #CCC !default; // Color of preloader and "1 of X" indicator
$controls-text-color-hover:       #FFF !default;
$IE7support:                      true !default; // Very basic IE7 support

// Iframe-type options
$include-iframe-type:             true !default;
$iframe-padding-top:              40px !default;
$iframe-background:               #000 !default;
$iframe-max-width:                900px !default;
$iframe-ratio:                    9/16 !default;

// Image-type options
$include-image-type:              true !default;
$image-background:                #444 !default;
$image-padding-top:               40px !default;
$image-padding-bottom:            40px !default;
$include-mobile-layout-for-image: true !default; // Removes paddings from top and bottom

// Image caption options
$caption-title-color:             #F3F3F3 !default;
$caption-subtitle-color:          #BDBDBD !default;

// A11y
$use-visuallyhidden:              false !default; // Hide content from browsers, but make it available for screen readers 



////////////////////////
// 2. General styles
////////////////////////

//SHOPIFY Fix
body.template-product {
	
	.mfp-content,
	.mfp-figure {
		height: 100%;
		width: 100%;
		
		@media (min-width: 480px) {
			img.mfp-img {
				min-height: 200%;
				max-height: 200%;
				max-width: none;
				
				position: absolute;
				left: 50%;
				@include translateX(-50%);
				
			}
		}
		
		@media (max-width: 480px) {
			overflow-x: hidden;
			overflow-y: scroll;
			
			img.mfp-img {
				width: 150%;
				max-width: none;
				margin-left: -25%;
				margin-top: 20%;
				max-height: none !important;
			}
		}
	}
}

// Transluscent overlay
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-base + 2;
  overflow: hidden;
  position: fixed;

  background: $overlay-color;
  
}

// Wrapper for popup
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-base + 3;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  padding: 0 $popup-padding-left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}

// Vertical centerer helper
.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top .mfp-container:before {
  display: none;
}

// Popup content holder
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: $z-index-base + 5;
  
}
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
  cursor: w-resize;
}

// Cursors
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur {
  cursor: url(../images/x-close.svg), pointer;
  
  &:hover {
	  cursor: url(../images/x-close.svg), pointer;	  
  }
  
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
  cursor: w-resize;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select:none;
  -moz-user-select: none;
  user-select: none;
}

// Hide the image during the loading
.mfp-loading.mfp-figure {
  display: none;
}

// Helper class that hides stuff
@if $use-visuallyhidden {
  // From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
  .mfp-hide {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }
} @else {
  .mfp-hide {
    display: none !important;
  }
}


////////////////////////
// 3. Appearance
////////////////////////

// Preloader and text that displays error messages
.mfp-preloader {
  color: $brand-primary;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: $z-index-base + 4;
  
  .h5 {
	  margin-bottom: 0;
  }
}
.mfp-preloader a {
  color: $brand-primary;
}
.mfp-preloader a:hover {
  color: $brand-inverse;
}

// Hide preloader when content successfully loaded
.mfp-s-ready .mfp-preloader {
  display: none;
}

// Hide content when it was not loaded
.mfp-s-error .mfp-content {
  display: none;
}


// CSS-reset for buttons
// button.mfp-close,
// button.mfp-arrow {
//   overflow: visible;
//   cursor: pointer;

//   border: 0;
//   -webkit-appearance: none;
//   display: block;
//   padding: 0;
//   z-index: $z-index-base + 6;
//   -webkit-box-shadow: none;
//   box-shadow: none;
// }
// button::-moz-focus-inner {
//     padding: 0;
//     border: 0
// }


// Close icon
.mfp-figure > .mfp-close {
	width: 80px;
	height: 80px;
	background: transparent;
	position: fixed;
	right: 240px;
	left: auto;
	top: 7px;
	text-decoration: none;
	text-align: center;
	padding: 0 0 20px 10px;
	color: white;
	
	font-style: normal;
	font-size: 9px;
	text-transform: uppercase;
	
	z-index: 1050;
	
	background-image: url(../images/_svg/closer.svg);
	background-repeat: no-repeat;
	background-size: 30px 40px;
	background-position: center, center;
	float: right;
	
	@include single-transition(opacity, $transDur);
	
	&:hover,
	&:focus {
		@include opacity(0.4);
		cursor: pointer;
	}

  @include media("<md") {
    right: -7px;
    top: 60px;
  }
	
}
.mfp-close-btn-in .mfp-close {
  color: $inner-close-icon-color;
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: $controls-color;
  text-align: left;
  
  &:hover {
	  color: $controls-text-color;
  }
}

// "1 of X" counter
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: $controls-text-color;
  font-size: 12px;
  line-height: 18px;
}

// Navigation arrows
@if $include-arrows {
  button.mfp-arrow {
  	background: transparent;
    position: absolute;
    opacity: $controls-opacity;

    margin: 0;
    top: 120px;
    bottom: 120px;

    padding: 0;
    width: 33%;
    height: auto;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

	 @include single-transition(opacity, $transDur);
	
  	&:hover {
  		@include opacity(0.4);
  	}
  	
  	&:before, 
    &:after {
  		content: none;  		
  	}
  	
  }
  
  
  .mfp-arrow:hover,
  .mfp-arrow:focus,
  .mfp-content .mfp-arrow:hover {
	  outline: none;
	  -moz-outline: none;
  }

  .mfp-arrow-left {
    left: 0px;

    &:hover {
//      cursor: url('../images/_svg/arrow-left.svg'), auto;
      cursor: url('../images/arrows/finger-aaron-left.png'), auto;

    }
    
    &:before,
    &:after {
      content: none;
    }

  }


  .mfp-arrow-right {
    right: 0px;
    
    &:hover {
      //cursor: url('../images/_svg/arrow-right.svg'), auto;
      cursor: url('../images/arrows/finger-aaron-right.png'), auto;

    }

    &:before,    
    &:after {
      content: none;
    }
  }
  
  
}




// Animation
@-webkit-keyframes fadeIn {
    0% {@include opacity(0);}
    100% {@include opacity(1);}
}

@-moz-keyframes fadeIn {
    0% {@include opacity(0);}
    100% {@include opacity(1);}
}

@-o-keyframes fadeIn {
    0% {@include opacity(0);}
    100% {@include opacity(1);}
}

@keyframes fadeIn {
    0% {@include opacity(0);}
    100% {@include opacity(1);}
}

.mfp-open .mfp-figure {
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    -ms-animation-duration: 1.5s;
    -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
}


// Image content type
@if $include-image-type {

  /* Main image in popup */
  img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: $image-padding-top 0 $image-padding-bottom;
    margin: 0 auto;
  }

  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
  }
  .mfp-bottom-bar {
	padding-left: 50px;
	margin-left: 20px;
    margin-top:  -32px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 80%;
    cursor: auto;
  }
  
  .mfp-title {
    text-align: left;
    color: $caption-title-color;
    word-wrap: break-word;
    
    a {
	    color: white;
	    margin-left: 15px;
	    margin-right: -5px;
	    
	    &:hover {
		    color: $controls-text-color;
	    }
    }
    
  }

  .mfp-figure {
    color: $caption-subtitle-color;
    display: block;
    font-size: 9px;
    line-height: 14px;
    text-transform: uppercase;

    &:after {
      content: none !important;
    }
  }
  .mfp-image-holder .mfp-content {
    max-width: 100%;
  }

  .mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
  }


  @if $include-mobile-layout-for-image {

      @media screen and (max-width: 800px) and (orientation:landscape), screen and (max-height: 300px) {
        /**
         * Remove all paddings around the image on small screen
         */
        .mfp-img-mobile .mfp-image-holder {
          padding-left: 0;
          padding-right: 0;
        }
        .mfp-img-mobile img.mfp-img {
          padding: 0;
        }
        /* The shadow behind the image */
        .mfp-img-mobile .mfp-figure:after {
          top: 0;
          bottom: 0;
        }

        .mfp-img-mobile .mfp-bottom-bar {
          background: rgba(0,0,0,0.6);
          bottom: 0;
          margin: 0;
          top: auto;
          padding: 3px 5px;
          position: fixed;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
        .mfp-img-mobile .mfp-bottom-bar:empty {
          padding: 0;
        }
        .mfp-img-mobile .mfp-counter {
          right: 5px;
          top: 3px;
        }
        .mfp-img-mobile .mfp-close {
          top: 0;
          right: 0;
          width: 35px;
          height: 35px;
          line-height: 35px;
          background: rgba(0, 0, 0, 0.6);
          position: fixed;
          text-align: center;
          padding: 0;
        }
        .mfp-img-mobile .mfp-figure small {
          display: inline;
          margin-left: 5px;
        }
      }
  }

}



	/*  MOBILE (PORTRAIT)
	 * ------------------------------------------------ */
	@media only screen and (max-width: 767px) {

		.mfp-close {
			top: 0;
			right: 0;
			padding: 40px;
			background-repeat: no-repeat;
		}

		img.mfp-img {
			min-height: none !important;
		}

	}


/* overlay at start */
.mfp-img-mobile.mfp-bg {
  opacity: 0;
  @include single-transition(all, $transDur*2);
}
/* overlay animate in */
.mfp-img-mobile.mfp-bg.mfp-ready {
  @include opacity(1);
}
/* overlay animate out */
.mfp-img-mobile.mfp-bg.mfp-removing {
  @include opacity(0);
}

/* content at start */
.mfp-img-mobile.mfp-wrap .mfp-content {
  @include opacity(0);
  @include single-transition(all, $transDur);
}
/* content animate it */
.mfp-img-mobile.mfp-wrap.mfp-ready .mfp-content {
  @include opacity(1);
}
/* content animate out */
.mfp-img-mobile.mfp-wrap.mfp-removing .mfp-content {
  @include opacity(0);
}

/* buttons animate out */
.mfp-img-mobile.mfp-wrap.mfp-removing .mfp-arrow {
  @include opacity(0);
}



// IE7 support
// Styles that make popup look nicier in old IE
@if $IE7support {
  .mfp-ie7 {
    .mfp-img {
      padding: 0;
    }
    .mfp-bottom-bar {
      width: 600px;
      left: 50%;
      margin-left: -300px;
      margin-top: 5px;
      padding-bottom: 5px;
    }
    .mfp-container {
      padding: 0;
    }
    .mfp-content {
      padding-top: 44px;
    }
    .mfp-close {
      top: 0;
      right: 0;
      padding-top: 0;
    }
  }
}