.site-footer {
	background: $black;
	color: $white;
	width: 100%;
	padding: $spacer/4;
	position: relative;
	z-index: 1;

	p {
		font-size: $font-size-xs;
	}
}