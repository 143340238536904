body.transition-out {

	.contact-text h5,
	.social-icons .social-link svg {
		transition: opacity 500ms;
		opacity: 0;
	}

}

.contact-text h5,
.social-icons .social-link svg {
	transition: opacity 500ms;
	opacity: 1;
}