body.contact {

    background: linear-gradient(226deg, #a4d9cb, #a4bcd9, #b8a4d9, #d9a4d0, #d9a4a6, #d9cfa4, #b7d9a4);
    background-size: 1400% 1400%;
    -webkit-animation: AA-Contact-Bg 30s ease infinite;
    -moz-animation: AA-Contact-Bg 30s ease infinite;
    -o-animation: AA-Contact-Bg 30s ease infinite;
    animation: AA-Contact-Bg 30s ease infinite;


    .container-fluid {
        h5 {
            mix-blend-mode: overlay;
            color: darken($gray-dark, 10);
            
            @include media(">md") {
                font-size: $font-size-h1; 
            }

            a {
                color: darken($gray-dark, 10);

                &:hover {
                    color: $black;
                    text-decoration: none;
                }
            }
        }
    }
}

@-webkit-keyframes AA-Contact-Bg {
    0%{background-position:94% 0%}
    50%{background-position:7% 100%}
    100%{background-position:94% 0%}
}
@-moz-keyframes AA-Contact-Bg {
    0%{background-position:94% 0%}
    50%{background-position:7% 100%}
    100%{background-position:94% 0%}
}
@-o-keyframes AA-Contact-Bg {
    0%{background-position:94% 0%}
    50%{background-position:7% 100%}
    100%{background-position:94% 0%}
}
@keyframes AA-Contact-Bg {
    0%{background-position:94% 0%}
    50%{background-position:7% 100%}
    100%{background-position:94% 0%}
}

