@-webkit-keyframes MOVE-BG {
    from {
        background-position: 0% 0%
    }
    to { 
        background-position: 100% 100%
    }
}



.cube-container {
  width: 200px;
  height: 200px;
  position: absolute;
  perspective: 1000px;


	.cube {
		width: 100%;
		height: 100%;
		position: absolute;
		transform-style: preserve-3d;

		figure {
			margin: 0;
			width: 202px;
			height: 202px;
			display: block;
			position: absolute;
			border: 2px solid transparent;
			background-color: rgba($white, 0.85);
			//background-size: contain;
			background-size: 50%;
			background-repeat: repeat;

			background-image: url('../../../../uploads/2017/06/blackorangecheck-80x80.jpg');

			-webkit-animation-name: MOVE-BG;
			-webkit-animation-duration: 1s;
			-webkit-animation-timing-function: linear;
			-webkit-animation-iteration-count: infinite;

		}

	}


	.front  { transform: rotateY(   0deg ) translateZ( 100px ); }
	.back   { transform: rotateX( 180deg ) translateZ( 100px ); }
	.right  { transform: rotateY(  90deg ) translateZ( 100px ); }
	.left   { transform: rotateY( -90deg ) translateZ( 100px ); }
	.top    { transform: rotateX(  90deg ) translateZ( 100px ); }
	.bottom { transform: rotateX( -90deg ) translateZ( 100px ); }

}


//Position / Rotate Cubes
//

.cube-container:nth-of-type(1) {
	bottom: 10px;
	left: 55%;

	.cube {
		transform: translateZ( -100px ) rotateY( -50deg );	
	}
}

.cube-container:nth-of-type(2) {
	bottom: 40px;
	right: 7.5%;
	z-index: -1;

	.cube {
		transform: translateZ( -120px ) rotateY( 50deg ) rotateX( -20deg);	
	}
}

.cube-container:nth-of-type(3) {
	bottom: 100px;
	left: 13%;

	.cube {
		transform: translateZ( -620px ) rotateY( 70deg ) rotateX( 25deg);	
	}
}

.cube-container:nth-of-type(4) {
	bottom: 160px;
	left: 40%;
	z-index: 2;

	.cube {
		transform: translateZ( -20px ) rotateY( -70deg ) rotateZ(-10deg);	
	}
}

.cube-container:nth-of-type(5) {
	bottom: 220px;
	right: -10px;

	.cube {
		transform: translateZ( -580px ) rotateY( 40deg ) rotateZ(15deg);	
	}
}

.cube-container:nth-of-type(6) {
	bottom: 260px;
	right: 10%;

	.cube {
		transform: translateZ( -120px ) rotateY( 240deg ) rotateZ(15deg);	
	}
}


.cube-container:nth-of-type(7) {
	bottom: 320px;
	right: 30%;

	.cube {
		transform: translateZ( -120px ) rotateY( 20deg ) rotateZ(-15deg);
	}
}



.cube-container:nth-of-type(8) {
	bottom: 260px;
	left: 25%;
	z-index: 2;

	.cube {
		transform: translateZ( -120px ) rotateY( -115deg ) rotateZ(115deg);	
	}
}

.cube-container:nth-of-type(9) {
	bottom: 360px;
	left: 15%;

	.cube {
		transform: translateZ( -90px ) rotateY( -115deg ) rotateZ(-115deg);	
	}
}


.cube-container:nth-of-type(10) {
	bottom: 480px;
	right: 7%;

	.cube {
		transform: translateZ( -10px ) rotateY( 55deg ) rotateZ(-20deg);	
	}
}


.cube-container:nth-of-type(11) {
	bottom: 520px;
	right: 35%;
	z-index: 2;

	.cube {
		transform: translateZ( 50px ) rotateY( -115deg ) rotateZ(-115deg);	
	}
}

.cube-container:nth-of-type(12) {
	bottom: 560px;
	left: 25%;
	z-index: 3;

	.cube {
		transform: translateZ( 120px ) rotateY( 15deg ) rotateZ(-115deg);	
	}
}

.cube-container:nth-of-type(13) {
	bottom: 860px;
	right: 2%;

	.cube {
		transform: translateZ( -520px ) rotateY( 15deg ) rotateZ(-115deg);	
	}
}

.cube-container:nth-of-type(14) {
	top: 320px;
	left: 5%;

	.cube {
		transform: translateZ( -520px ) rotateY( 15deg ) rotateZ(-115deg);	
	}
}

.cube-container:nth-of-type(15) {
	top: 400px;
	right: 18%;
	z-index: 2;

	.cube {
		transform: translateZ( 220px ) rotateY( 15deg ) rotateZ(215deg);
	}
}

.cube-container:nth-of-type(16) {
	top: 260px;
	left: 20%;

	.cube {
		transform: translateZ( -20px ) rotateY( 75deg ) rotateZ(-115deg);	
	}
}

.cube-container:nth-of-type(17) {
	top: 200px;
	left: 40%;
	z-index: 3;

	.cube {
		transform: translateZ( 320px ) rotateY( 95deg ) rotateZ(-15deg);	
	}
}


.cube-container:nth-of-type(18) {
	top: 60px;
	right: 20%;

	.cube {
		transform: translateZ( -820px ) rotateY( 75deg ) rotateZ(-115deg);	
	}
}

.cube-container:nth-of-type(19) {
	top: 60px;
	left: 2%;

	.cube {
		transform: translateZ( -820px ) rotateY( 5deg ) rotateZ(-225deg);	
	}
}

.cube-container:nth-of-type(20) {
	top: 60px;
	right: 10%;

	.cube {
		transform: translateZ( -20px ) rotateY( 75deg ) rotateZ(-115deg);	
	}
}


.cube-container:nth-of-type(21) {
	top: 0px;
	left: 20%;

	.cube {
		transform: translateZ( -920px ) rotateY( 155deg ) rotateZ(115deg);	
	}
}
