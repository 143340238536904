.project-desc-trigger {
	position: absolute;
	top: $spacer/2;
	left: $spacer/2;
	z-index: 9999;

	width: 40px;
	height: 40px;

	.closer {
		display: none;
	}

	&.open {
		.bubble {
			display: none;
		}
		.closer {
			display: inline-block;
			margin-top: 8px;
			margin-left: 10px;
		}
	}

	@include media("<md") {
		right: 70px;
		top: $spacer / 1.65;
		left: auto;
	}
}

.project-description {
	left: 0;
	top: 0;
	position: absolute;
	z-index: 20;
	background: $white;
	padding: $spacer * 2;
	width: 100%;
	height: 100%;

	background-color: rgba($white, 0.8);
	// background: {
	// 	image: url(../images/background-studio.jpg);
	// 	size: cover;
	// 	position: center;
	// 	attachment: fixed;
	// }

	&.open {
		display: block !important;
	}

	.project-text {
		@include center-both();
		width: 90%;
		max-width: 700px;

		* {
			text-align: center;
		}
	}

}

body.melting-rainbows .project-desc-trigger,
.open > .project-desc-trigger {
	display: block !important;
}

body.melting-rainbows {
	.project-desc-trigger,
	.project-description {
		position: fixed;
	}
}