#map,
#map-public {
    width: 100%;
    height: 100vh;
    z-index: 2;

    > div {
    	position: fixed !important;
    	top: 0;
    	left: 0;
    	height: 100vh !important;
    	z-index: 3;
    }

    img {
        max-width: inherit !important;
    }


    *.testing {
        color: green;
        fill: green !important;
        * {
            fill: green !important;
        }
    }
}

#map .slide-wrap {
    position: relative;
    width: 100%;

    .slider {
        padding-bottom: 0;
        height: 400px;
        margin: 0;

        .slide {
            text-align: center;
        }

        img {
            display: inline-block;
            width: auto !important;
            height: auto !important;
            max-height: 400px;
            max-width: 100% !important;
        }
    }
}

//Map Download
//

.map-download {
    position: fixed;
    bottom: $spacer;
    left: $spacer;
    z-index: 2;

    background: linear-gradient(226deg, darken(#a4d9cb, 40), darken(#a4bcd9, 40), darken(#b8a4d9, 40), darken(#d9a4d0, 40), darken(#d9a4a6, 40), darken(#d9cfa4, 40), darken(#b7d9a4, 40));
    background-size: 1400% 1400%;
    -webkit-animation: AA-Contact-Bg 60s ease infinite;
    -moz-animation: AA-Contact-Bg 60s ease infinite;
    -o-animation: AA-Contact-Bg 60s ease infinite;
    animation: AA-Contact-Bg 60s ease infinite;

    @include media("<sm") {
        left: $spacer/2;
        bottom: $spacer/2;
    }

}


//Map Title
//

.map-title {

    position: fixed;
    top: $spacer;
    left: $spacer;
    z-index: 3;
    width: 90%;
    max-width: 500px;

}


//Public Installations
//

.iw-full {
    background: transparent !important;
    //padding: 0 !imporant;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 90vh;
    z-index: 100;
    padding-top: 25px;

    .marker-header,
    .marker-slide {
        text-align: center;
    }

    > div {
        max-height: none !important;
        max-width: none !important;

        .slider {
            max-width: none !important;

            img {
                max-height: 85vh !important;
                max-width: calc(100vw - 80px);
                width: auto !important;
                display: inline-block
            }
        }
    }
}