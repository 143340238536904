/**
 * Color Hover Utilites
 */
$colors:

	(black			$black),
	(white 			$white),

	(gray			$gray),
	(gray-light		$gray-light),
	(gray-dark		$gray-dark),

;

@each 
	$name, 
	$value
	
	in $colors {
	
	.text-#{$name} {
		color: $value;
		border-color: $value !important;

		a {
			color: $value;
		}
	}

	.bg-#{$name} {
		background-color: $value;
		
		u,
		.text-underline,
		.text-underline-smart,
		*[style*="underline"] {
			text-shadow: 1px 0 0 $value, -1px 0 0 $value !important;
		}

	}

	.hover-#{$name}:hover,
	.hover-#{$name}:focus,
	.hover-#{$name}:active {
		color: $value;
		border-color: $value;
	}

	.hoverbg-#{$name}:hover,
	.hoverbg-#{$name}:focus,
	.hoverbg-#{$name}:active {
		background-color: $value;
	}

	
}