.btn-primary {

	&:hover {
		background: $gray-light;
	}
}


.btn-link {
	color: $white;
	padding: 0;

	&:hover,
	&:active,
	&:focus {
		color: $white;
	}
}