body {
	transition: background 1500ms, background-gradient 1500ms;

	&.transition-out {
		background: white linear-gradient(226deg, #fff, #fff);
		transition: background 1500ms, background-gradient 1500ms;
	
			//Navigation
			.site-navigation {		
				
				//Show Main
				~ * {
					transition-delay: 500ms;
					opacity: 1;
				}
				

				//Fly Away!!

				.not-open {
					transition: top 500ms, left 500ms;
				}

				#about.not-open {
					top: 120% !important;
					left: -12% !important;
				}


				#projection-mapping.not-open {
					top: 50% !important;
					left: -13% !important;
				}


				#three-dimensional-sculptures.not-open {
					top: 12% !important;
					left: -16% !important;
				}


				#paintings.not-open {
					top: -25% !important;
					left: 30% !important;
				}

				#public-installations.not-open {
					top: -25% !important;
					left: 57% !important;
				}

				#melting-rainbows.not-open {
					top: -22% !important;
					left: 80% !important;
				}

				#piss-on-the-city.not-open {
					top: 50% !important;
					left: 110% !important;
				}

				#contact.not-open {
					top: 120% !important;
					left: 80% !important;
				}


			}
	
	}

}