.flex-video {
	height: 0;
	position: relative;
	width: 100%;

	iframe {
		@include center-both;
		width: 100%;
		height: 100%;
	}
}