//Targets the triangle that comes up from the marker
div[style="width: 0px; height: 0px; border-right: 10px solid transparent; border-left: 10px solid transparent; border-top: 24px solid rgba(0, 0, 0, 0.0980392); position: absolute; left: 243px; top: 371px;"],
div[style="border-top-width: 24px; position: absolute; left: 243px; top: 368px;"] {
	display: none;
}

//Marker Info Window
.gm-style-iw {
	background: $white;
	padding: $spacer/2 $spacer 0;

	> div {
		display: block !important; 
	}

	& + div {
		top: $spacer/2 !important;
		right: $spacer/1.75 !important;
		opacity: 1 !important;
	}

	&:hover {
		.marker-image {

		}

		.marker-header {

		}
	}
}

//Targets the x (close) img link on the info window
img[src="https://maps.gstatic.com/mapfiles/api-3/images/mapcnt6.png"] {
	top: $spacer;
	right: $spacer;
}