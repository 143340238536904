.painting-set {
	position: relative;
	height: 25vh;
	overflow: hidden;

	&:hover {
		z-index: 2;

		&.open {
			z-index: auto;
		}
	}
	
	.painting-set-link {
		position: absolute;
		transform-style: preserve-3d;
  		display: flex;
  		background: transparent;

		width: 100%;
		height: 100%;
		//left: -25%;
		//top: -25%;
		left: 0;
		top: 0;


		align-items: center;
		justify-content: center;
		transform-style: preserve-3d;

		background: {
			size: cover;
			position: center;
		}

		.painting-title {
			will-change: transform;
			transition: .15s;
			position: absolute;
			left: 0px;
			width: 100%;
			height: auto;
			transform: translateZ(100px);
			transition: opacity 750ms;
			opacity: 0;

			@include center-vertical;

			//mix-blend-mode: color-dodge;
			color: $gray-dark;

			font: {
				family: $headings-font-family;
				size: $font-size-h2;
				weight: bold;
			}
		
		}


		.painting-thumbnail {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			background: {
				size: cover;
				position: center;
			}

		}


		&:hover {
			background: $white;

			.painting-thumbnail {
				opacity: 0.5;
				-mix-blend-mode: hard-light;
			}

			.painting-title {
				opacity: 1;
				//mix-blend-mode: luminosity;
//				color: darken($gray-dark, 10);
			}
		}

	}			


	.painting-slides {
		display: none;
		width: 100%;
	}

	&.open {
		height: 100vh;
		background-image: none !important;
		padding-left: 0 !important;
		padding-right: 0 !important;

		.painting-set-link {
			display: none;
		}

		.slides {
			display: inline-block;
		}
	}
}



.painting-slides {
	padding-top: 10vh;
	mix-blend-mode: multiply;

	.slide {
		text-align: center;

		.painting-figure {
			display: inline-block;
			text-align: left;

			@include media("<md") {
				figcaption {
					padding-left: $spacer/2;
					padding-right: $spacer/2;
				}
			}
		}
	
		.img-fluid {
			width: auto;
			max-height: 80vh;
			max-width: 100%;
			min-width: 0;
			display: inline-block;

			mix-blend-mode: screen;
		}

	}

}