.home .main {
	pointer-events: none;
}

.home-featured-image {
	@include center-horizontal;
	left: 48%;
	position: fixed;
	bottom: 0;

	height: 85vh;
	width: auto;
//	mix-blend-mode: multiply;

	@include media("<md") {
		@include center-none;
		position: relative;
		left: 0;
		height: auto;
		width: 100%;
	}
}