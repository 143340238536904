// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Wedding', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       'Work Sans', sans-serif;

//Fonts
//
$font-family-circular: 	'Circular', serif;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 14px !default;

$font-size-base: 1rem !default;
$font-size-lg:   1.15rem !default;
$font-size-sm:   .75rem !default;
$font-size-xs:   .65rem !default;

$line-height-base: 1.5 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: inherit !default;
$display2-weight: inherit !default;
$display3-weight: inherit !default;
$display4-weight: inherit !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family:   $font-family-circular;
$headings-font-weight:   bold !default;
$headings-line-height:   1.25 !default;
$headings-color:         inherit !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-light !default;

$abbr-border-color: $gray-light !default;

$blockquote-small-color:  $gray-light !default;
$blockquote-font-size:    ($font-size-base * 1.25) !default;
$blockquote-border-color: $gray-lighter !default;
$blockquote-border-width: .25rem !default;

$hr-border-color: rgba(0,0,0,.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: bold !default;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba(0,0,0,.25) !default;
$nested-kbd-font-weight: bold !default;

$list-inline-padding: 5px !default;