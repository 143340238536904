.lazy-load-wrapper {
	background: {
		image: url('../images/_svg/loading-gooey.svg');
		position: center;
		repeat: no-repeat;
		size: 50px 50px;
	}

	img {
		transition: opacity $transDur * 2, background $transDur*2;
	}
}

.lazy-hidden {
	opacity: 0;
	background: transparent;
}

.lazy-loaded {
	opacity: 1;
	padding-bottom: 0 !important;
	background: white;
}