@include media("<md") {

	.site-navigation {
		width: 100%;
		position: relative;
		margin: $spacer*3 0 0;
	}

	.home-project-link {
		position: relative;
		display: inline-block;
		width: 48%;
		text-align: center;
		margin-bottom: $spacer*2;


		&#projection-mapping,
		&#piss-on-the-city {
			display: none;
		}
		
		svg {
			display: inline-block;
			width: 60%;
		}
	}

}

@include media(">md") {

	.site-navigation {
		width: 100vw;
		height: 100vh;
		position: fixed;
	}

	.home-project-link {
		position: absolute;
		width: 10%;
		max-width: 110px;

		&:hover {
			&:after {
				content: attr(data-title);
				font: {
					family: $headings-font-family;
					size: $font-size-sm;
				}

				@include center-horizontal;
				bottom: -25px;
				width: 200px;
				text-align: center;
			}
		}


		&.not-this {
			filter: grayscale(1);
		}



/*		ORIGINAL
************************

		&#about {
			top: 80%;
			left: 12%;
		}


		&#projection-mapping {
			top: 52%;
			left: 13%;
		}


		&#three-dimensional-sculptures {
			top: 22%;
			left: 16%;
		}


		&#paintings {
			top: 5%;
			left: 31%;
		}

		&#public-installations {
			top: 5%;
			left: 53.5%;
		}

		&#melting-rainbows {
			top: 22%;
			left: 70%;
		}

		&#piss-on-the-city {
			top: 52%;
			left: 77%;
		}


		&#contact {
			top: 80%;
			left: 78%;
		}

 */

		&#about {
			top: 80%;
			left: 12%;
		}


		&#projection-mapping {
			top: 50%;
			left: 13%;
		}


		&#three-dimensional-sculptures {
			top: 18%;
			left: 21%;
		}


		&#paintings {
			top: 4.75%;
			left: 41%;
		}

		&#public-installations {
			top: 14.5%;
			left: 63.5%;
		}

		&#melting-rainbows {
			top: 48%;
			left: 75%;
		}

		&#piss-on-the-city {
			top: 52%;
			left: 77%;

			display: none !important;
		}


		&#contact {
			top: 80%;
			left: 78%;
		}

		&#about,
		&#contact {
			&:hover {
				&:after {
					content: none;
				}
			}
		}
	}

}


//For Single Posts
//

body.single,
body.page {
	
	.site-navigation {
		display: none;
		z-index: $zindex-tooltip - 1;
	}

	&.menu-open {

		> * {
			opacity: 0.15;
		}

		.site-navigation,
		.brand,
		.brand-bunny {
			opacity: 1
		}

		.site-navigation {
			display: block;
		}
	}


}