.error-text-wrap {
	@include center-both;
	//position: fixed;
	z-index: 12;
}
.error-image {
	@include center-both;
	position: fixed;
	width: auto;
	height: auto;
	min-width: 120vw;
	min-height: 120vh;
	//z-index: 1;
}