* {

	&:hover,
	&:focus,
	&:active {
		outline: none !important;
		-moz-outline: none !important;
		box-shadow: none !important;
		
	}
}

$transDur: 500ms;