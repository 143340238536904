.home-project-link {
	
	&:hover {
		animation: Floating 2s infinite ease-in-out; 
	}

}


@-webkit-keyframes Floating {
    from {
    	transform: translateX(0) translateY(0);
    }
    50% {
    	transform: translateX(0px) translateY(10px);
    }
    to {
    	transform: translateX(0) translateY(0);
    }    
}