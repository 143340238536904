body.post-template-template-sculptures {
	background: $black;
	color: $white;

	a {
		color: $white;
	}

	.brand svg * {
		fill: $gray-dark;
	}

}

.sculpture-video {
	position: absolute;
	max-width: 100%;
	max-height: 100%;
	top: 0;
	left: 0;
	z-index: 0;

}

	.cover {
		width: 100vw;
		height: 100vh;
		position: absolute;
		z-index: 1;

	    background: linear-gradient(180deg, #a4d9cb, #a4bcd9, #b8a4d9, #d9a4d0, #d9a4a6, #d9cfa4, #b7d9a4);
	    background-size: 400% 400%;
	    -webkit-animation: AA-Contact-Bg 30s ease infinite;
	    -moz-animation: AA-Contact-Bg 30s ease infinite;
	    -o-animation: AA-Contact-Bg 30s ease infinite;
	    animation: AA-Contact-Bg 30s ease infinite;


		mix-blend-mode: multiply;
		pointer-events: none;
	}



.sculptures {
	margin-top: 105vh;
	background: transparent;
	z-index: 2;
	overflow-x: hidden;
	overflow-y: visible;

	@include media("<sm") {
		margin-top: 0;
	}
}

.sculpture-set {
	width: 100%;
	position: relative;
	display: inline-block;

	.sculpture-set-link {
		display: inline-block;
		position: relative;
		//height: 500px;
		//width: 500px;

		img {
			width: 100%;
			display: inline-block;
			//position: absolute;

			@include media(">md") {
				width: 115%;
			}
		}

		&:hover:after {
			content: attr(data-project);
			@include center-horizontal;
			bottom: -40px;
			//color: $white;
			font: {
				family: $headings-font-family;
				size: $display3-size;
			}
		}
	}

	.sculpture-slides {
		display: none;
		width: 100%;

	}

	&.open {
		position: relative;
		overflow: hidden;
		height: 100vh;
		margin-left: 0 !important;

		color: $black;

		background: {
			color: $white;
			image: url(../images/background-studio.jpg);
			size: cover;
			position: center;
			attachment: fixed;
		}

		margin-bottom: -10px !important;

		& + .sculpture-set {
			margin-top: 0 !important;
		}

		.sculpture-set-link {
			display: none;
		}

		.slides {
			display: inline-block;
		}
	}
}



.sculpture-slides {
	padding-top: 10vh;

	.slide {
		text-align: center;

		.painting-figure {
			display: inline-block;
			text-align: left;
		}
	
		.img-fluid {
			width: auto;
			max-height: 80vh;
			max-width: 100%;
			min-width: none;
			display: inline-block;
		}

	}

}


.js-tilt {

    width: 200px;
    height: 150px;
    display: block;
    background-color: #9e21ff;
    background-image: -webkit-linear-gradient(315deg, #ed21ff 0%, #9e21ff 100%, #9e21ff 100%);
    background-image: linear-gradient(135deg, #ed21ff 0%, #9e21ff 100%, #9e21ff 100%);
    box-shadow: 0 3px 47px rgba(0, 0, 0, 0.2);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: perspective(300px);
    transform: perspective(300px);	
}