.brand {
	position: fixed;
	top: $spacer/3;
	right: $spacer * 2;

	width: 160px;
	height: 60px;

	z-index: $zindex-tooltip;

	svg {
		width: 100%;
		height: 100%;

		* {
			fill: $black;
		}
	}

	&:hover {
		svg * {
			fill: $gray-light;
		}
	}

	@include media("<md") {
		left: $spacer/2;
		right: auto;
	}
}


//bunny
//
.brand-bunny {
	position: fixed;
	top: $spacer/3;
	right: $spacer;

	width: $spacer/1.5;
	height: $spacer * 1.75;

	z-index: $zindex-tooltip;

	svg {
		width: 100%;
		height: 100%;

		filter: grayscale(0);
	}

	&:hover {

		svg {
			filter: grayscale(1);
		}
	}

	@include media("<md") {
		right: $spacer/2;
	}
}