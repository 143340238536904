// Smart Underline
// 

u,
.text-underline,
.text-underline-smart,
*[style*=underline] {
	text-decoration: none !important;
	text-shadow: 1px 0 0 $white, -1px 0 0 $white;
	background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
	background-repeat: repeat-x;
	background-position: 0 91%;
	-webkit-background-size: 100% 2px;
	background-size: 100% 2px;
}

.no-underline {
	text-decoration: none;
	text-shadow: 0 !important;
	background-image: none;
}