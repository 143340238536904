body,
html {

	background: {
		image: url(../images/background-studio.jpg);
		size: cover;
		position: center;
		attachment: fixed;
	}

	&.no-scroll {
		overflow: hidden;
		position: fixed;
	}

	
	@include media(">md") {
	
		main.main {
			min-height: 100vh;
		}

	}

}