// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   40px !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 2),
    y: ($spacer-y * 2)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  ),
  4: (
    x: ($spacer-x * 4),
    y: ($spacer-y * 4)
  ),
  5: (
    x: ($spacer-x * 5),
    y: ($spacer-y * 5)
  ),
  6: (
    x: ($spacer-x * 6),
    y: ($spacer-y * 6)
  ),
  7: (
    x: ($spacer-x * 7),
    y: ($spacer-y * 7)
  ),
  8: (
    x: ($spacer-x * 8),
    y: ($spacer-y * 8)
  ),
  9: (
    x: ($spacer-x * 9),
    y: ($spacer-y * 9)
  ),
  10: (
    x: ($spacer-x * 10),
    y: ($spacer-y * 10)
  ),


  -1: (
    x: (-$spacer-x),
    y: (-$spacer-y)
  ),
  -2: (
    x: (-$spacer-x * 2),
    y: (-$spacer-y * 2)
  ),
  -3: (
    x: (-$spacer-x * 3),
    y: (-$spacer-y * 3)
  ),
  -4: (
    x: (-$spacer-x * 4),
    y: (-$spacer-y * 4)
  ),
  -5: (
    x: (-$spacer-x * 5),
    y: (-$spacer-y * 5)
  ),
  -6: (
    x: (-$spacer-x * 6),
    y: (-$spacer-y * 6)
  ),
  -7: (
    x: (-$spacer-x * 7),
    y: (-$spacer-y * 7)
  ),
  -8: (
    x: (-$spacer-x * 8),
    y: (-$spacer-y * 8)
  ),
  -9: (
    x: (-$spacer-x * 9),
    y: (-$spacer-y * 9)
  ),
  -10: (
    x: (-$spacer-x * 10),
    y: (-$spacer-y * 10)
  ),  



) !default;
$border-width: 0px !default;