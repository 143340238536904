body.post-template-template-projectionmap {

	background: $black;
	color: $white;

	a {
		color: $white;
	}

	.brand svg * {
		fill: $gray-dark;
	}

	.ui-slider .ui-slider-handle,
	.ui-slider-horizontal {
		background: $white;
	}

	.projection-object {
		min-height: 100vh;
		overflow-x: hidden;
		overflow-y: visible;
	}

	.projection-controls {
		height: 100vh;
		overflow: scroll;
	}

}

.projection-thumbnail-gallery {
	@include justified-menu;
	background: transparent;
	bottom: 0;
	position: fixed;
	right:0;
	width: 350px;
	z-index: 2;

	&.p-1 {
		padding-bottom: 0 !important;
	}

	li {
		width: 17%;
		margin-bottom: $spacer/4;

		&:hover {
			cursor: pointer;

			img {
				filter: grayscale(0.5);
			}
		}

		img {
			filter: grayscale(1);
		}

		.active-video {

			img {
				filter: grayscale(0);
			}
		}
	}
}



//Videos
//

.ayahuasca-video {
	filter: brightness(1.5);
}