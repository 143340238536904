.video-list {
    height: 100vh;
    width: 100%;
    position: relative;
}

.video-video {

    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover; 

}


//Header
//

.video-header {
    position: absolute;
    z-index: 10;
    bottom: -100px;
    width: 100%;
    left: 0;
    opacity: 0;
    transition: bottom 500ms;

    a {
        color: $white;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
        
    }

    h1 {
        margin-bottom: $spacer/3;
    }

    &.active {
        bottom: $spacer * 1.5;
        opacity: 1;
    }
}


//Images
//

.video-images {
    min-height: 100vh;

    .video-image-image {
        padding-right: 10px;
        padding-left: 10px;
        //margin-bottom: 5px;

        &:hover {
            cursor: pointer;
        }
    }
}

.video-image-set {

    .image-slides {
        display: none;
        width: 100%;
    }


    //Open
    &.open {
        height: 100vh;
        flex: 100% !important;
        max-width: 100% !important;
        right: 0 !important;
        left: 0 !important;
        position: relative;
        overflow: hidden;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .image-set-link {
            display: none;
        }

        .slides {
            display: inline-block;
        }
    }
}



.image-slides {
    padding-top: 10vh;

    .slide {
        text-align: center;

        .image-figure {
            display: inline-block;
            text-align: left;
        }
    
        .img-fluid {
            width: auto;
            max-height: 80vh;
            max-width: 100%;
            min-width: none;
            display: inline-block;
        }

    }

}