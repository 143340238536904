.social-icons {
	text-align: center;
	margin-top: $spacer * 2;

	li {
		display: inline-block;
	}
		
	.social-link {
		display: inline-block;
		padding: .5rem;
		width: 4rem;
		height: 4rem;
		display: inline-block;

		svg {
			width: 2.5rem;
			height: 2.5rem;
			fill: darken($gray-dark, 10);
			
            mix-blend-mode: overlay;

		}

		&:hover {
			svg {
				fill: $black;
			}
		}
	}

}