body.login-action-login.wp-core-ui {

	* {
		&:active,
		&:focus {
			outline: none !important;
		}
	}

	#login {
		width: 90%;
		max-width: 600px;

		a {
			&:hover {
				color: $gray;
			}
		}
	}


    #login h1 a, 
    .login h1 a {
	    background-image: url('../images/_svg/logo.svg');
		height:0;
		padding-bottom: 30%;
		width: 100%;
		background-size: contain;
		background-position: right center;
		background-repeat: no-repeat;
		z-index: 2;
		position: relative;
    }


	#loginform {
		position: relative;
		z-index: 1;
		background: transparent;
		border: 0;
		padding-top: 80px;
		box-shadow: none !important;

		label {
			display: inline-block;
			width: 100%;
			margin-bottom: $spacer*2;
		}

		input[type="text"],
		input[type="password"] {
			@extend .form-control;
			box-shadow: none !important;
			width: 100%;
			text-align: left;
			border-bottom: 1px solid $black;

			&:focus {
				border-bottom-color: $gray-light;
			}
		}

		input[type="checkbox"] {
			//@extend .input-checkbox;
			background-color: $white;
			height: 10px;
			width: 10px !important;
			min-width: 10px;
			max-width: 10px;

			&:hover,
			&:focus,
			&:active {
				box-shadow: 0;
				outline: none;
				border-color: $black;
			}

			&:checked {
				background: $black;

				&:before {
					content: none;
				}
			}
		}

		input[type="submit"] {
			@extend .btn;
			@extend .btn-primary;
			text-transform: uppercase;
			border-radius: 0 !important;
			box-shadow: none !important;
			outline: none !important;
			border: none !important;
			text-shadow: none !important;
			height: auto;

		}
	}

	
	p#nav {
		text-align: center;

		a:hover {


		}
	}

	#backtoblog {
		display: none;
	}

}