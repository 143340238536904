// Project Colors
//
// Colors specific to this project



// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$black:				#000;
$white:				#fff;

$gray-dark:         #373a3c !default;
$gray:				#55595c !default;
$gray-light:		#818a91 !default;
$gray-lighter:		#eceeef !default;
$gray-lightest:		#f7f7f9 !default;

$brand-primary:		$black 	!default;
$brand-success:		green 	!default;
$brand-info:		blue 	!default;
$brand-warning:		orange 	!default;
$brand-danger:		red  	!default;
$brand-inverse:		$gray-dark !default;



// Body
//
// Settings for the `<body>` element.

$body-bg:    		$white !default;
$body-color: 		$black !default;


// Links
//
// Style anchor elements.

$link-color:            $black !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
